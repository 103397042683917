import { connect } from "react-redux";
import { resetAllSDPProductsFilter } from "app/pages/SmartDP/Listing/SmartDPFilters/smartDPProductsFilterActionCreators";
import { bindActionCreators } from "redux";
import SmartDPImmersiveSearch from "app/pages/SmartDP/Search/SmartDPImmersiveSearch/SmartDPImmersiveSearch";
import { getPartnerMarketing } from "app/reducers/partnerSelector";

const mapStateToProps = (state, props) => {
	const marketing = getPartnerMarketing(state);
	const {
		heroTitleDesktop,
		heroTitleMobile,
		heroSubtitleDesktop,
		heroSubtitleMobile,
		heroTextAlign,
		heroImageMobile,
		heroImageDesktop,
		heroImageMobileThumbnail,
		heroImageDesktopThumbnail,
		hideSmartDPForm,
	} = props;
	return {
		heroTitleDesktop: heroTitleDesktop || marketing?.heroTitleDesktop,
		heroTitleMobile: heroTitleMobile || marketing?.heroTitleMobile,
		heroSubtitleDesktop: heroSubtitleDesktop || marketing?.heroSubtitleDesktop,
		heroSubtitleMobile: heroSubtitleMobile || marketing?.heroSubtitleMobile,
		heroTextAlign: heroTextAlign || marketing?.heroTextAlign,
		heroImageMobile: heroImageMobile || marketing?.heroImageMobile,
		heroImageDesktop: heroImageDesktop || marketing?.heroImageDesktop,
		heroImageMobileThumbnail: heroImageMobileThumbnail || marketing?.heroImageMobileThumbnail,
		heroImageDesktopThumbnail:
			heroImageDesktopThumbnail || marketing?.heroImageDesktopThumbnail,
		hideSmartDPForm: hideSmartDPForm || marketing?.hideSmartDPForm,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			resetAllSDPProductsFilter,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SmartDPImmersiveSearch);
