import { memo, useEffect } from "react";
import PropTypes from "prop-types";
import "./SmartDPSearch.scss";
import { sendTagOnSDPSearchPage } from "app/utils/analytics";
import { DynamicBlocksRendererContainer } from "app/pages/.shared/DynamicBlocks";
import SmartDPImmersiveSearchContainer from "app/pages/SmartDP/Search/SmartDPImmersiveSearch/SmartDPImmersiveSearchContainer";
import { PushNotificationContainer } from "app/pages/.shared/PushNotification/PushNotificationContainer";
import HeadMetadata from "app/utils/metas/HeadMetadata";

const SmartDPSearch = ({ homeBlocks = [] }) => {
	useEffect(() => {
		sendTagOnSDPSearchPage();
	}, []);

	return (
		<div className="smartdp-search">
			<HeadMetadata page="sdp-search" />
			<SmartDPImmersiveSearchContainer />
			<DynamicBlocksRendererContainer blocks={homeBlocks} />
			<PushNotificationContainer />
		</div>
	);
};

SmartDPSearch.propTypes = {
	homeBlocks: PropTypes.array,
};

export default memo(SmartDPSearch);
